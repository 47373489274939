#about {
  padding-top: 5%;
}

.text {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "Helvetica Neue" !important;
  line-height: 1.8;
  margin-top: 15px;
  font-size: 14px !important;
}

.img-slide2 {
  width: 100% !important;
  height: 250px !important;
  border-radius: 20px;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .text {
    font-weight: 500;
    line-height: 1.6;
    text-align: left;
  }
}
