/* 仮置き */
.image-container {
  /* 画像コンテナの最大幅をウィンドウ幅から左右の余白を引いた値に設定 */
  /* max-width: calc(
    100% - 90px
  );  */
  margin: 0px auto; /* 上下20pxの余白を取り、左右を自動的に中央配置 */
  overflow: hidden; /* オーバーフローを隠す */
  padding-top: 50px;
}

.image-contents {
  width: 100%; /* 画像の幅を100%に設定 */
  height: auto; /* 高さを自動調整 */
  display: block; /* ブロックレベル要素として表示 */
}

.kari {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin: 0;
}

.text-container {
  text-align: center;
}

.large-text {
  font-size: 36px;
  line-height: 1.5;
}

.medium-text {
  font-size: 24px;
  line-height: 1.5;
}

.small-text {
  font-size: 18px;
  line-height: 1.5;
}
/*
Theme Name: Telly Hotel
Theme URI: 
Author: 
Author URI: 
Description: Telly Hotel is specially designed product packaged for Telly Hotel by TemplatesJungle.
Version: 
*/

/*--------------------------------------------------------------
This is main CSS file that contains custom style rules used in this template
--------------------------------------------------------------*/

/*------------------------------------*\
    Table of contents
\*------------------------------------*/

/*------------------------------------------------

CSS STRUCTURE:

1. VARIABLES

2. GENERAL TYPOGRAPHY
  2.1 General Styles
  2.2 Background Color
  2.3 Border Radius
  2.4 Section
    - Section Paddings
    - Section Margins
    - Section Title
    - Content width
  2.5 Buttons
    - Button Sizes
    - Button Shapes
    - Button Color Scheme
    - Button Aligns

3. CONTENT ELEMENTS
  3.1 Pattern Overlay
  3.2 Button Hover Effects
  3.3 Button Hover Effects2
  3.4 Text hover Effects
  3.5 Scroll Button

4. SITE STRUCTURE
  4.1 Header 4.1 Header
    - Offcanvas
  4.2 Intro
  4.3 About
  4.4 Activities
  4.5 Acheivement
  4.6 Our Rooms
  4.7 Testimonials
  4.8 Booking
  4.9 Video
  4.10 Latest Post
  4.11 Contact
  4.12 Newsletter
  4.13 Footer

    
/*--------------------------------------------------------------
/** 1. VARIABLES
--------------------------------------------------------------*/
:root {
  --accent-color: #333;
  --black-color: #000;
  --dark-color: #111;
  --gray-color-100: #f9f7f5;
  --gray-color-200: #e1e1e1;
  --gray-color-300: #d1d1d1;
  --gray-color-400: #ced4da;
  --gray-color-500: #adb5bd;
  --dark-gray-color: #474341;
  --bs-light-rgb: rgba(255, 255, 255, 1);
  --light-color: #fff;
  --bs-light-rgb: rgba(255, 255, 255, 1);
  --primary-color: #ff8103;
  --swiper-theme-color: #807e7c !important;
}

/* on mobile devices below 600px
*/
@media screen and (max-width: 600px) {
  :root {
    --header-height: 100px;
    --header-height-min: 80px;
  }
}

/* Fonts */
:root {
  --body-font: "IBM Plex Sans", sans-serif;
  --heading-font: "Old Standard TT", serif;
}

/*----------------------------------------------*/
/* 2 GENERAL TYPOGRAPHY */
/*----------------------------------------------*/

/* 2.1 General Styles
/*----------------------------------------------*/
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  box-sizing: border-box;
}
body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
  color: var(--accent-color);
  margin: 0;
}
ul.inner-list li {
  font-size: 1.2em;
  color: var(--dark-gray-color);
}
a {
  color: var(--dark-gray-color);
  text-decoration: none;
  transition: 0.3s color ease-out;
}
a.light {
  color: var(--light-color);
}
a.stretched-link {
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
  color: var(--dark-color);
}
p.light {
  color: var(--light-color);
}
span.light {
  color: var(--light-color);
}
svg.light {
  color: var(--light-color);
}
table.light tr {
  color: var(--light-color);
}
b,
strong {
  font-weight: bold;
}
.highlight {
  background: var(--dark-color);
  color: var(--light-color);
}

/* 2.2 Background Color
/*----------------------------------------------*/
.bg-gray-100 {
  background: var(--gray-color-100);
}

/* 2.3 Border Radius
/*----------------------------------------------*/
.border-rounded-10 {
  border-radius: 10px;
}

/* 2.4 Section
/*----------------------------------------------*/

/* - Section Padding
--------------------------------------------------------------*/
.padding-small {
  padding-top: 2em;
  padding-bottom: 2em;
}
.padding-medium {
  padding-top: 4em;
  padding-bottom: 4em;
}
.padding-large {
  padding-top: 7em;
  padding-bottom: 7em;
}
.padding-xlarge {
  padding-top: 10em;
  padding-bottom: 10em;
}

/* - Section margin
--------------------------------------------------------------*/
.margin-small {
  margin-top: 3em;
  margin-bottom: 3em;
}
.margin-medium {
  margin-top: 5em;
  margin-bottom: 5em;
}
.margin-large {
  margin-top: 7em;
  margin-bottom: 7em;
}
.margin-xlarge {
  margin-top: 9em;
  margin-bottom: 9em;
}

@media only screen and (max-width: 768px) {
  .margin-small,
  .margin-medium,
  .margin-large {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

/* - Section Title
--------------------------------------------------------------*/
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: var(--heading-font);
  font-weight: 500;
  line-height: 1;
}
h1.light,
h2.light,
h3.light,
h4.light,
h5.light {
  color: var(--light-color);
}
h1.heading {
  font-size: 6.5em;
}
h2 {
  font-size: 2.8em;
}
h3 {
  font-size: 1.6em;
}

p {
  font-size: 0.8em;
}

.en-family {
  font-family: var(--heading-font);
  font-weight: 700;
}

/* - Section width
--------------------------------------------------------------*/
.container-md {
  max-width: 1460px;
}
.container-lg {
  max-width: 1750px;
}

/*--------------------------------------------------------------
/** 2.5 Buttons
--------------------------------------------------------------*/
/* - Button Sizes
------------------------------------------------------------- */
.btn.btn-small {
  padding: 0.8em 1.8em;
  font-size: 0.85em;
}
.btn.btn-medium {
  padding: 1.2em 2.7em;
  font-size: 1em;
}
.btn.btn-large {
  padding: 0.8em 4.6em;
  font-size: 1.6em;
}
.btn.btn-full {
  display: block;
  margin: 0.85em 0;
  width: 100%;
  letter-spacing: 0.12em;
}

/* - Button Shapes
------------------------------------------------------------- */
.btn.btn-rounded,
.btn.btn-rounded::after {
  border-radius: 6px;
}
.btn.btn-pill,
.btn.btn-pill::after {
  border-radius: 2em;
}
/* button outline */
.btn.btn-outline-dark,
.btn.btn-outline-light,
.btn.btn-outline-accent {
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.btn.btn-outline-dark:hover::after,
.btn.btn-outline-light:hover::after {
  background-color: transparent;
}
.btn.btn-outline-dark {
  border-color: rgba(0, 0, 0, 0.4);
  color: var(--dark-color);
  transition: 0.5s ease-in-out;
}
.btn.btn-outline-dark:hover {
  background: var(--dark-color);
  color: var(--light-color);
}
.btn.btn-outline-light {
  border-color: rgba(255, 255, 255, 1);
  color: var(--light-color);
  transition: 0.5s ease-in-out;
}
.btn.btn-outline-light:hover {
  color: var(--dark-color);
}
.btn.btn-outline-gray {
  background: transparent;
  border-color: var(--gray-color-200);
  color: var(--dark-color);
}
.btn.btn-outline-gray:hover {
  color: var(--light-color) !important;
}
.btn.btn-outline-accent {
  background: transparent;
  border-color: var(--accent-color);
  color: var(--dark-color);
}
.btn.btn-outline-accent:hover {
  border-color: var(--dark-color);
  color: var(--dark-color) !important;
}

/* - Buttons Color Scheme
------------------------------------------------------------- */
.btn.btn-normal {
  text-decoration: underline;
  border: none;
  font-weight: 800;
}
.btn.btn-normal:hover,
.btn.btn-normal:focus {
  text-decoration: none;
  box-shadow: none;
}
.btn.btn-accent {
  color: var(--light-color);
  background-color: var(--accent-color);
  border: none;
}
.btn.btn-accent:hover {
  color: var(--light-color) !important;
  background-color: var(--primary-color);
}
.btn.btn-black {
  background-color: var(--dark-color);
  color: var(--light-color);
  border: none;
}
.btn.btn-black:hover {
  background-color: var(--dark-color);
  color: var(--light-color);
}
.btn.btn-light {
  background-color: var(--light-color);
  color: var(--dark-color);
  border: none;
  transition: 0.5s ease-in-out;
}
.btn.btn-light:hover {
  background-color: var(--primary-color);
  color: var(--light-color);
}
.btn-dark {
  color: var(--light-color);
  background-color: var(--dark-color);
  border-color: var(--dark-color);
  transition: 0.5s ease-in-out;
}
.btn-dark:hover {
  color: var(--dark-color);
}

/* - Buttons Aligns
------------------------------------------------------------- */
.btn-left {
  text-align: left;
  display: block;
}
.btn-center {
  text-align: center;
  display: block;
}
.btn-right {
  text-align: right;
  display: block;
}

/*----------------------------------------------*/
/* 3 CONTENT ELEMENTS */
/*----------------------------------------------*/

/* 3.1 Pattern Overlay
------------------------------------------------------------- */
.pattern-overlay.pattern-right {
  top: -190px;
  right: -70px;
  z-index: -1;
}
.pattern-overlay.pattern-left {
  top: 0;
  left: 0;
  z-index: -1;
}

/* 3.2 Button Hover Effects
------------------------------------------------------------- */
.btn.btn-arrow,
button.btn-arrow {
  color: var(--light-color);
  letter-spacing: 1.5px;
  overflow: hidden;
  transition: color 0.1s cubic-bezier(0.16, 0.08, 0.355, 1),
    background 0.1s cubic-bezier(0.16, 0.08, 0.355, 1);
}
.btn.btn-arrow:hover,
button.btn-arrow:hover {
  color: var(--light-color);
}
.btn-arrow {
  position: relative;
  transition: background-color 300ms ease-out;
}
.btn-arrow span {
  display: inline-block;
  position: relative;
  transition: all 300ms ease-out;
  will-change: transform;
}
.btn-arrow:hover span {
  transform: translate3d(-1rem, 0, 0);
}
.btn-arrow svg {
  position: absolute;
  width: 0.9em;
  right: 0;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 300ms ease-out;
  will-change: right, opacity;
}
.btn-arrow svg * {
  stroke-width: 5;
  /* えらーのため */
  /* stroke-color: transparent; */
}
.btn-arrow:hover svg {
  opacity: 1;
  right: -2rem;
}

/* 3.3 Button Hover Effects 2
------------------------------------------------------------- */
.btn.btn-outline-arrow.light-hover {
  color: var(--light-color);
  border: 1px solid var(--light-color);
}
.btn.btn-outline-arrow.dark-hover {
  color: var(--dark-color);
  border: 1px solid var(--dark-color);
}
.btn.btn-outline-arrow span {
  display: inline-block;
  transition: all 300ms ease-out;
  will-change: transform;
}
.btn.btn-outline-arrow:hover span {
  transform: translate3d(-7px, 0, 0);
}
.btn.btn-outline-arrow svg {
  position: absolute;
  top: 32px;
  right: 21px;
  transform: translateY(-50%);
  transition: all 300ms ease-out;
  will-change: right, opacity;
}
.btn.btn-outline-arrow:hover svg {
  opacity: 0;
  right: 0;
}

/* 3.4 Text hover Effects
------------------------------------------------------------- */
.text-hover ul li > a,
.text-hover a,
a.text-hover {
  position: relative;
  transition: 0.3s ease-out;
}
.text-hover li a.nav-link:after,
.text-hover li a:after,
.text-hover a:after,
a.text-hover:after {
  content: "";
  width: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: 0.3s ease-out;
  border-bottom: 1px solid;
}
.text-hover a.active:after,
.text-hover .active a:after,
.text-hover.active a.nav-link:after,
.text-hover a.nav-link:hover:after,
.text-hover li a:hover:after,
.text-hover a:hover:after,
a.text-hover:hover:after,
.text-hover li a:focus:after,
.text-hover a:focus:after,
a.text-hover:focus:after {
  width: 100%;
}
.text-hover li a.border-0:after,
.text-hover.border-0 li a:after {
  border: none;
}
.text-hover.dropdown-menu li a:hover {
  padding-left: 23px;
}

/* 3.5 Scroll Button
------------------------------------------------------------- */
#scroll-top-btn {
  position: fixed;
  right: 30px;
  bottom: 100px;
  z-index: 9;
  font-size: 16px;
  outline: none;
  background-color: #000;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
#scroll-top-btn svg {
  fill: var(--gray-color-100);
  font-weight: 600;
}

/*----------------------------------------------*/
/* 4 SITE STRUCTURE */
/*----------------------------------------------*/

/* 4.1 Header
------------------------------------------------------------- */
.site-header {
  width: 100%;
  z-index: 10;
}
#navbar a.nav-link:hover,
#navbar a.nav-link:focus {
  color: var(--light-color);
}
.navbar-toggler .navbar-icon {
  width: 40px;
  height: 40px;
  fill: var(--light-color);
  outline: none;
}

@media only screen and (max-width: 1400px) {
  #header-nav .btn-box {
    display: none;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
}

/* - Offcanvas
------------------------------------------------------------- */
#header-nav .offcanvas.show {
  z-index: 9999;
  background-color: var(--gray-color-100);
}
.offcanvas.show .nav-item a.nav-link,
#header-nav .offcanvas.show .nav-item a:hover {
  font-size: 2em;
  color: var(--dark-color);
}
.offcanvas.show .offcanvas-body .navbar-nav {
  align-items: unset !important;
  padding-left: 20px;
}

@media (min-width: 1399px) {
  #header .navbar-expand-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  #header .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  #header .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

/* 4.2 Intro
------------------------------------------------------------- */
#intro .banner-content {
  top: 30%;
  left: 300px;
  z-index: 3;
  width: 54%;
}
#intro .banner-content p {
  width: 85%;
}
#intro .social-links {
  top: 50%;
  right: 40px;
  bottom: 0;
}
#intro .social-links a:hover {
  text-decoration: underline;
  color: var(--light-color);
}

.img-fluid {
  width: 100%;
}

@media only screen and (max-width: 1455px) {
  #intro .image-holder img {
    height: 910px;
    object-fit: cover;
  }
  #intro .banner-content {
    top: 20%;
    left: 100px;
    width: 55%;
  }
}

@media only screen and (max-width: 680px) {
  #intro .image-holder img {
    height: 600px;
    object-fit: cover;
  }
  #intro .banner-content {
    left: 40px;
    width: 88%;
  }
  #intro h1.banner-title {
    font-size: 3em;
  }
  #intro .social-links {
    top: 850px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/* 4.3 About
------------------------------------------------------------- */
/* いったんけします
#about .container {
  max-width: 930px;
} */
#about p {
  font-size: 1.2em;
}

/* 4.4 Activities
------------------------------------------------------------- */
/* #activities .image-holder img {
  width: 100%;
}
#activities .icon-box svg {
  fill: var(--dark-color);
  width: 40px;
  height: 40px;
}
#activities .detail {
  width: 60%;
}

@media only screen and (max-width: 1280px) {
  #activities .detail {
    width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  #activities .detail {
    padding: 120px 0;
  }
} */

/* 4.5 Acheivement
------------------------------------------------------------- */
/* #achievements .counter-number h5 > .number-item {
  font-size: 5.9em;
}
#achievements .counter-number span.digital-item {
  font-size: 4.5em;
}
#achievements .counter-info p {
  letter-spacing: 0.12em;
} */

/* 4.6 Our Rooms
------------------------------------------------------------- */
/* #our-rooms .product-card .detail {
  width: 100%;
  height: 358px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  padding: 40px;
  transition: 0.9s all;
}
#our-rooms .product-card:hover .detail {
  bottom: 50px;
  padding: 80px 40px;
  opacity: 1;
  background-color: var(--gray-color-100);
}
#our-rooms .swiper-wrapper {
  margin-bottom: 40px;
}
#our-rooms .swiper-arrow svg {
  top: 30%;
  padding: 18px;
  width: 60px;
  height: 60px;
  z-index: 3;
  background-color: var(--gray-color-100);
}
#our-rooms svg.icon-arrow-left {
  left: 60px;
}
#our-rooms svg.icon-arrow-right {
  right: 60px;
}
#our-rooms .swiper-arrow svg:hover {
  background-color: var(--gray-color-100);
}

@media only screen and (max-width: 901px) {
  #our-rooms .swiper-arrow svg {
    top: 73%;
  }
}

@media only screen and (max-width: 767px) {
  #our-rooms .swiper-arrow svg {
    top: 68%;
  }
} */

/* 4.7 Testimonials
------------------------------------------------------------- */
/* #testimonials blockquote {
  font-size: 2em;
  font-family: var(--heading-font);
}
#testimonials .swiper-pagination {
  bottom: 80px;
}
#testimonials .swiper-pagination span.swiper-pagination-bullet {
  margin-right: 25px !important;
} */

/* 4.8 Booking
------------------------------------------------------------- */
/* .booking-form .booking-item {
  width: 24%;
}
.booking-form .datetime-container button span.month-day {
  font-size: 5.5em;
  font-family: var(--heading-font);
  width: 50%;
}
.booking-form .datetime-container button > span {
  display: inline;
  font-size: 0;
  text-transform: capitalize;
}
.booking-form .datetime-container span {
  font-size: 18px;
}
.datetime-container button span.week-day {
  display: none;
}
.booking-form .datetime-container button.date,
.booking-form .datetime-container button.time {
  background-color: var(--light-color);
  border-radius: 0;
  display: flex;
  align-items: baseline;
}
.booking-form .datetime-container button.active {
  background-color: var(--light-color);
}
.booking-form .picker {
  width: 500px;
}
.booking-form .picker table td.active,
.booking-form .picker table td.time-selected {
  background-color: var(--primary-color);
  color: var(--light-color);
}
.booking-form button:after {
  content: "";
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" id="arrow-down" viewBox="0 0 24 24"> <path fill="currentColor" d="m12 13.171l4.95-4.95l1.414 1.415L12 16L5.636 9.636L7.05 8.222l4.95 4.95Z"/> </svg>');
  position: absolute;
  top: 80px;
  left: 153px;
  width: 20px;
  height: 20px;
  z-index: 9;
}

.booking-form input.booking {
  font-size: 5.5em;
  font-family: var(--heading-font);
  color: var(--dark-color) !important;
  border: none;
  width: 50%;
  outline: none;
  position: relative;
}
.booking-form input.booking::placeholder {
  color: var(--dark-color);
}
.booking-form input::-webkit-outer-spin-button,
.booking-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

@media only screen and (max-width: 1200px) {
  .booking-form .booking-item {
    width: 50%;
  }
  .booking-form button:after {
    left: 191px;
  }
}

@media only screen and (max-width: 500px) {
  .booking-form .booking-item {
    width: 100%;
  }
  .booking-form button:after {
    left: 265px;
  }
  .booking-form .datetime-container button span.month-day {
    width: 30%;
  }
} */

/* 4.9 Video
------------------------------------------------------------- */
/* #video-player a.play-btn {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#myModal .modal-dialog {
  max-width: 980px;
}

@media only screen and (max-width: 901px) {
  #video-player img {
    height: 480px;
    object-fit: cover;
  }
} */

/* 4.10 Latest Post
------------------------------------------------------------- */
/* .post-grid .meta-date {
  line-height: 0;
}
.post-grid .meta-date span.meta-day {
  font-size: 3.5em;
  font-family: var(--heading-font);
  line-height: 1;
}
.post-grid .card-detail h3.card-title {
  line-height: 1.3;
}
.post-grid .card-detail p {
  width: 90%;
} */

/* 4.11 Contact
------------------------------------------------------------- */
/* #contact .contact-detail {
  width: 60%;
  padding: 70px 0;
}
.contact-detail textarea,
.contact-detail input {
  font-family: var(--heading-font);
  height: 36px;
  border-bottom: 1px solid var(--gray-color-200) !important;
}
.contact-detail textarea:focus,
.contact-detail input:focus {
  outline: none;
} */

/* 4.12 Newsletter
------------------------------------------------------------- */
/* #newsletter input[type="text"] {
  width: 100%;
  height: 75px;
  border: 1px solid var(--gray-color-200) !important;
  background: transparent;
  border-radius: 35px;
  font-family: var(--heading-font);
}
#newsletter .btn-subscribe {
  top: 5px;
  right: 5px;
}

@media only screen and (max-width: 530px) {
  #newsletter .btn-subscribe {
    top: 83px;
    width: 100%;
  }
  #newsletter .btn-subscribe button {
    width: 100%;
  }
} */

/* 4.13 Footer
------------------------------------------------------------- */
.footer-menu .social-links li {
  width: 54px;
  height: 54px;
  cursor: pointer;
  transition: 0.6s ease-in-out;
}
.footer-menu .social-links li:hover {
  background-color: var(--gray-color-200);
}

.payment-methods img {
  height: 24px;
}

/* ここから */
/* ここから */

/* 2.1 General Styles
/*----------------------------------------------*/
img:not(.logo) {
  display: inline-block;
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/*--- Section Title
-----------------------------------------------*/
.section-header {
  position: relative;
}
.section-title {
  font-size: 3.8em;
  line-height: 1;
  font-weight: 900;
}
.section-header .title span {
  font-size: 14px;
  color: #afafaf;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-left: 30px;
  position: relative;
}
.section-header span:before {
  content: "";
  border-bottom: 2px solid #c5a992 /*var(--accent-color)*/;
  position: absolute;
  top: 9px;
  left: 3px;
  width: 14px;
  align-items: center;
}

/* - Section Padding
--------------------------------------------------------------*/
.padding-xsmall {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.padding-small {
  padding-top: 2em;
  padding-bottom: 2em;
}
.padding-medium {
  padding-top: 4em;
  padding-bottom: 4em;
}
.padding-large {
  padding-top: 7em;
  padding-bottom: 7em;
}
.padding-xlarge {
  padding-top: 9.5em;
  padding-bottom: 9.5em;
}
.padding-2xlarge {
  padding-top: 15em;
  padding-bottom: 15em;
}

@media only screen and (max-width: 768px) {
  .padding-small,
  .padding-medium,
  .padding-large,
  .padding-xlarge {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

/* - Section margin
--------------------------------------------------------------*/
.margin-small {
  margin-top: 3em;
  margin-bottom: 3em;
}
.margin-medium {
  margin-top: 4.5em;
  margin-bottom: 4.5em;
}
.margin-large {
  margin-top: 6em;
  margin-bottom: 6em;
}
.margin-xlarge {
  margin-top: 7.5em;
  margin-bottom: 7.5em;
}

@media only screen and (max-width: 768px) {
  .margin-small,
  .margin-medium,
  .margin-large,
  .margin-xlarge {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

/* - Section bg colors
--------------------------------------------------------------*/
.bg-dark {
  background: var(--dark-color);
}
.content-light a,
.content-light {
  color: #fff;
}

/*----- About us Section
--------------------------------------------------------------*/
#about figure {
  margin-right: 50px;
}
#about img.single-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  #about img.single-image {
    height: auto;
  }
}

/*--------------------------------------------------------------
/** 5.7 Post Grid
--------------------------------------------------------------*/
.post-grid .post-item figure {
  margin-bottom: 0;
}
.post-grid .post-content {
  width: 100%;
  padding: 40px 30px;
  margin-bottom: 60px;
  box-shadow: -7px 12px 41px -10px rgb(0 0 0 / 12%);
  -webkit-box-shadow: -7px 12px 41px -10px rgb(0 0 0 / 12%);
  -moz-box-shadow: -7px 12px 41px -10px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.post-grid .post-item .post-title {
  font-size: 1.2em !important;
  line-height: 1.4;
}
.post-grid .post-item .meta-date {
  font-size: 14px;
  padding-bottom: 20px;
}
.post-grid .post-item p {
  line-height: 2;
}

@media only screen and (max-width: 850px) {
  .post-grid .post-item .post-image {
    width: 100%;
  }
  .post-grid .post-content {
    padding: 40px 10px;
  }
  .post-grid .post-item .post-title {
    font-size: 1.8em;
  }
}
/*----- Services Section
--------------------------------------------------------------*/
.services-item {
  position: relative;
  margin-top: 60px;
  margin-right: 100px;
  padding: 0 10px;
}
.services-item:last-child {
  margin-right: 0;
}
.services-item .number {
  font-family: var(--heading-font);
  font-size: 5em;
  font-weight: 900;
  color: #ffffff;
  /* color: #f4f4f4; */
  position: absolute;
  top: -69px;
  left: -12px;
  z-index: -1;
}
.services-item p {
  line-height: 2;
}

/* @media only screen and (max-width: 1199px) {
  .services-item {
    margin-right: 50px;
  }
}
@media only screen and (max-width: 600px) {
  .services-item {
    margin-bottom: 50px;
  }
  .services-item:last-child {
    margin-right: 50px;
  }
} */

/*--------------------------------------------------------------
/** 5.6 Portfolio Tabs
--------------------------------------------------------------*/
.portfolio-tabs .tab-content {
  position: relative;
}
.portfolio-tabs .tab-content .grid {
  flex-wrap: wrap;
}
.portfolio-tabs [data-tab-content] {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.portfolio-tabs .active[data-tab-content] {
  opacity: 1;
  visibility: visible;
  height: auto;
}
.portfolio-tabs .tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 55px 0;
  list-style: none;
}
.portfolio-tabs .tabs .tab {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 50px;
  margin-right: 20px;
  border: 1px solid #777;
  text-transform: uppercase;
  transition: all 0.3s ease-out;
}
.portfolio-tabs .tabs .tab.active,
.portfolio-tabs .tabs .tab:hover {
  background-color: #fff;
  color: var(--dark-color);
}
.portfolio-tabs .tab-content .grid {
  display: flex;
  justify-content: space-between;
}
.portfolio-tabs .tab-content a {
  display: block;
  width: 32%;
  margin-right: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}
.portfolio-tabs .tab-content a img {
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}
.portfolio-tabs .tab-content a:hover img {
  transform: scale(1.1);
}

@media only screen and (max-width: 992px) {
  .portfolio-tabs .tab-content a {
    width: 48%;
  }
}

@media only screen and (max-width: 850px) {
  .portfolio-tabs .tabs .tab {
    border: none;
    padding: 15px;
    margin-right: 0;
  }
  .portfolio-tabs .tabs .tab.active,
  .portfolio-tabs .tabs .tab:hover {
    background: none;
    color: var(--accent-color);
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-tabs .tab-content a {
    width: 100%;
  }
  .portfolio-tabs .tab-content a img {
    width: 100%;
    height: auto;
  }
}

/* 5.5 Testimonials
--------------------------------------------------------------*/
.testimonial-image {
  width: 100%;
  /* height: 484px;
  margin-right: 60px; */
}
.testimonial-block {
  margin-top: 20px;
}
.testimonial-block .testimonials-inner p {
  font-family: var(--secondary-font);
  font-size: 1.25em;
  line-height: 2;
  display: block;
  margin-bottom: 45px;
}

.testimonial-block .testimonial-author .name {
  font-size: 1.4em;
  font-weight: 900;
}

@media only screen and (max-width: 575px) {
  img.review-image {
    width: 100%;
    height: auto;
  }
}
