.map {
  width: 100%;
  aspect-ratio: 16/9;
}

.img-slide {
  height: 500px !important;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.all-box {
  margin: 0 auto 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.one-box {
  width: calc(50% - 10px);
  display: flex;
  /* flex-wrap: wrap; */
  border-bottom: 1px solid #b5b1b1;
}
.price-box {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #b5b1b1;
}

.box-title,
.box-text,
.price-title,
.price-text {
  width: calc(50%);
}

.box-title > p {
  font-weight: 900 !important;
}
.box-text > p,
.price-text {
  text-align: right;
}

.box-title,
.box-text > p {
  padding-top: 10px;
  /* margin-bottom: -10px; */
}

.tag-box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
}

.box {
  width: calc(25% - 10px); /* 4列横並びできるように幅を計算 */
  /* height: 100px; */
  padding: 15px 0;
  margin: 20px 0;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-text {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  font-family: "Old Standard TT", serif;
}

.comment-box {
  margin-top: 30px;
}
.comment-box > p {
  font-size: 15px;
}

/*画面幅480px以上の設定
------------------------------------*/
@media screen and (max-width: 600px) {
  .one-box {
    width: calc(100%);
  }
  .img-slide {
    width: 100% !important;
    height: 100% !important;
    /* object-fit: cover !important; */
  }
}
