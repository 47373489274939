table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.table-box {
  padding-top: 100px;
}

.table-box-color {
  margin: 15px;
  background-color: rgb(248, 248, 255);
}

/* table01 */
#table01 tr {
  border-bottom: 1px solid #b5b1b1;
}

#table01 th,
#table01 td {
  padding: 24px 0;
  border: none;
}

#table01 th {
  width: 30%;
}

/* sp */
@media only screen and (max-width: 480px) {
  #table01 th,
  #table01 td {
    width: 100%;
    display: block;
  }

  #table01 th {
    width: 100%;
  }

  #table01 td {
    padding-top: 0;
  }
}
