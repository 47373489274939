/* header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 90px;
  background: rgba(0, 0, 0, 0.404);
  color: white;
  position: fixed;
  z-index: 10;
 
} */
header {
  position: fixed;
  z-index: 5;
  width: 100%;
}

.c-header {
  align-items: center;
  background-color: #465ba8;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;
}

/* .c-header_list {
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 0;
} */

/* .c-header_list-item {
  list-style: none;
  text-decoration: none;
} */

/* .c-header_list-link {
  font-family: "Yu Gothic", "YuGothic", "游ゴシック", "Meiryo", sans-serif !important;
  color: #000;
  display: block;
  margin-right: 20px;

  text-decoration: none;
} */

/* .c-header_list-link:hover {
  filter: opacity(0.6);
} */

.c-hamburger-menu {
  position: relative;
}

.c-hamburger-menu__input {
  display: none;
}

.c-hamburger-menu_bg {
  background-color: #000;
  cursor: pointer;
  display: none;
  height: 100vh;
  width: 100%;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 0;
  z-index: -1;
}

#hamburger:checked ~ .c-hamburger-menu_bg {
  display: none;
  /* 見本は↓だけど↑じゃない？ */
  /* display: block; */
}

.c-hamburger-menu_button {
  display: none;
}

.c-hamburger-menu_button-mark {
  background-color: #000;
  display: block;
  height: 1px;
  transition: 0.3s;
  width: 20px;
}

@media screen and (max-width: 750px) {
  /* header {
    position: fixed;
    z-index: 5;
    width: 100%;
  } */

  .c-hamburger-menu_list {
    background-color: #465ba8;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 2rem;
    position: absolute;
    transform: translateX(-100%);
    transition: 0.3s;
    top: 100%;
    width: 100%;
  }
  #hamburger:checked ~ .c-hamburger-menu_list {
    transform: translateX(0%);
    transition: 0.3s;
  }

  .c-hamburger-menu_button {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 32px;
    justify-content: center;
    width: 32px;
  }
  #hambuger:checked
    ~ .c-hamburger-menu-button
    .c-hamburger-menu_button-mark:nth-of-type(1) {
    transform: translate(2px, 1px) rotate(45deg);
    transform-origin: 0%;
  }
  #hambuger:checked
    ~ .c-hamburger-menu-button
    .c-hamburger-menu_button-mark:nth-of-type(2) {
    opacity: 0;
  }
  #hambuger:checked
    ~ .c-hamburger-menu-button
    .c-hamburger-menu_button-mark:nth-of-type(3) {
    transform: translate(2px, 3px) rotate(-45deg);
    transform-origin: 0%;
  }
}

/* kezu */
/* kezu */
/* kezu */
/* kezu */
/* kezu */
/* kezu */
.haikei {
  background-color: #465ba8; /* カスタマイズしてください */
  /* position: fixed;
  z-index: 500 !important; */
}

.c-header {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem; /* カスタマイズしてください */
  width: 100%;
}

.c-header__logo {
  color: #000; /* カスタマイズしてください */
  min-width: 80px; /* カスタマイズしてください */
  text-decoration: none;
}

.c-header__list {
  box-sizing: border-box;
  display: flex;
  margin: 0;
  padding: 0;
}

.c-header__list-item {
  list-style: none;
  text-decoration: none;
}

.c-header__list-link {
  color: white; /* カスタマイズしてください */
  display: block;
  margin-right: 20px; /* カスタマイズしてください */
  text-decoration: none;
  padding: 10px 0px; /* カスタマイズしてください */
  font-family: "Yu Gothic", "YuGothic", "游ゴシック", "Meiryo", sans-serif;
  font-weight: 700;
}

.c-header__list-link:hover {
  filter: opacity(0.6); /* カスタマイズしてください */
}

.c-hamburger-menu {
  position: relative;
}

@media screen and (max-width: 750px) {
  .c-hamburger-menu__list {
    background-color: #465ba8; /* カスタマイズしてください */
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 0;
    padding: 2rem; /* カスタマイズしてください */
    position: absolute;
    transform: translateX(-100%);
    transition: 0.3s; /* カスタマイズしてください */
    top: 100%;
    width: 100%;
  }

  #hamburger:checked ~ .c-hamburger-menu__list {
    transform: translateX(0%);
    transition: 0.3s;
  }
}

.c-hamburger-menu__input {
  display: none;
}

.c-hamburger-menu__bg {
  background-color: white; /* カスタマイズしてください */
  cursor: pointer;
  display: none;
  height: 100vh;
  left: 0;
  opacity: 0.4; /* カスタマイズしてください */
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

#hamburger:checked ~ .c-hamburger-menu__bg {
  display: block;
}

.c-hamburger-menu__button {
  display: none;
}

@media screen and (max-width: 750px) {
  .c-hamburger-menu__button {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px; /* カスタマイズしてください */
    height: 32px; /* カスタマイズしてください */
    justify-content: center;
    width: 32px; /* カスタマイズしてください */
  }
}

.c-hamburger-menu__button-mark {
  background-color: white; /* カスタマイズしてください */
  display: block;
  height: 1px; /* カスタマイズしてください */
  transition: 0.3s; /* カスタマイズしてください */
  width: 20px; /* カスタマイズしてください */
}

@media screen and (max-width: 750px) {
  #hamburger:checked
    ~ .c-hamburger-menu__button
    .c-hamburger-menu__button-mark:nth-of-type(1) {
    transform: translate(2px, 1px) rotate(45deg); /* カスタマイズしてください */
    transform-origin: 0%; /* カスタマイズしてください */
  }
  #hamburger:checked
    ~ .c-hamburger-menu__button
    .c-hamburger-menu__button-mark:nth-of-type(2) {
    opacity: 0;
  }
  #hamburger:checked
    ~ .c-hamburger-menu__button
    .c-hamburger-menu__button-mark:nth-of-type(3) {
    transform: translate(2px, 3px) rotate(-45deg); /* カスタマイズしてください */
    transform-origin: 0%; /* カスタマイズしてください */
  }
}
