.footer-top {
  padding-bottom: 90px;
  padding: 90px;
  background-color: #eeeeee;
}

/*メニューをページ下部に固定*/
#sp-fixed-menu {
  position: fixed;
  width: 100%;
  bottom: 0px;
  font-size: 0;
  opacity: 0.9;
  z-index: 99;
  display: flex;
}

/*メニューを横並びにする*/
#sp-fixed-menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 0 10 0;
  width: 100%;
  justify-content: center;
}

#sp-fixed-menu li {
  justify-content: center;
  align-items: center;
  width: 90%;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

/*左側メニューを緑色に*/
#sp-fixed-menu li:first-child {
  background: #38b435;
}

/*左側メニューをオレンジ色に*/
#sp-fixed-menu li:last-child {
  background: #f3a324;
}

/*ボタンを調整*/
#sp-fixed-menu li a {
  color: #fff;
  text-align: center;
  display: block;
  width: 100%;
  padding: 20px;
  text-decoration: none;
}

/*PCの場合にはメニューを表示させない*/
@media (min-width: 768px) {
  .for-sp {
    display: none;
  }
}
