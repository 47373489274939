.pages-swiper {
  width: 100%;
  background-color: black;
}
.pages-swiper img {
  width: 100%;
  vertical-align: bottom;
}
.swiper-thumbs {
  cursor: pointer;
}

.Swiper1 .swiper-slide {
  opacity: 0.4;
}

.Swiper1 .swiper-slide-thumb-active {
  /* outline: 2px solid #000; */
  outline-offset: -2px;
  opacity: 1;
}

.img-slide3 {
  /* height: 50% !important; */
  /* height: 600px !important; */
  /* height: 400px !important; */
  object-fit: cover;
}
/*Google Map　レスポンシブにする為のものなので、基本的に編集不要です。
---------------------------------------------------------------------------*/
.iframe-box {
  width: 100%;
  height: 0;
  padding-top: 50%; /*マップの高さを増やしたい場合は、ここの数値を上げてみて下さい。*/
  position: relative;
  overflow: hidden;
}
.iframe-box iframe {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
