.showcase-video {
  width: 100%;
  /* height: 80vh; */
  height: 100%;
  object-fit: cover;

  /* ここから */
  /* opacity: 0.8; */
  /* position: absolute;
  top: 0;
  left: 0%; */
}

.text_hero {
  font-family: 游ゴシック体, "Yu Gothic", YuGothic, "Helvetica Neue" !important;
  line-height: 1.5;
  margin-top: 15px;
  font-size: 1rem !important;
  color: #fff !important;
  text-shadow: 0px 0px 13px rgba(20, 53, 179, 1);
}

@media only screen and (max-width: 480px) {
  .hero_image {
    width: 100% !important;
    height: 600px !important;
    object-fit: cover !important;
  }
}
