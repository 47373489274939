#on-sale {
  background-color: #f7f7f7;
}

.meta-date {
  display: flex;
}
.chips {
  border-radius: 50px;
  background: #eee;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0;
}

.chips-red {
  background: red;
  padding-left: 10px;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0 10px;
  color: white;
}

.chips-outofstock {
  border-radius: 50px;
  background: red;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0;
  color: white;
}
.salespoint {
  border-top: 1px solid #e0e0e0;
  margin: 10px 0;
  padding-top: 10px;
}

.salespoint p {
  padding-top: 20px;
}

.en-family {
  vertical-align: -10%;
}

.en-number {
  font-size: 1.6em;
  vertical-align: -5%;
}

@media screen and (max-width: 750px) {
  #on-sale {
    padding-top: 50px;
  }
}
