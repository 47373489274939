.container-add {
  text-align: center;
}

.section-business {
  padding-bottom: 50px;
}

.comment-title,
.comment-text {
  font-family: "Yu Gothic", "YuGothic", "游ゴシック", "Meiryo", sans-serif;
  font-weight: 700;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.grid-item,
.comment-item {
  background-color: #a5dbf7;
  border-radius: 5px;
  height: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* font-size: 18px; */
}

.grid-item2 {
  background-color: #a5dbf7;
  border-radius: 5px;
  height: auto;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.grid-item > h5 {
  margin: 0;
}
.comment-item {
  background-color: #f4f4f4;
  padding: 40px;
  margin: 20px 0 50px;
  /* margin-bottom: 60px; */
}
.comment-title {
  color: #00b8ee;
  padding-bottom: 30px;
}
.comment-text {
  text-align: left;
  margin: 0;
  padding-bottom: 5px;
}
.smaller-title {
  /* font-size: calc(50% - 2px); */
  color: gray;
  /* position: absolute; */
  /* top: -30px; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  margin-top: 10px;
  margin-bottom: 8px;
}

.image-container2 {
  margin-top: 20px;
  margin-bottom: 5%;
  position: relative;
  /* height: 50px; */
}

.image-container2 img {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  border-radius: 10px;
  width: 100%;
  height: 500px !important;
  object-fit: cover;
}

.centered-text p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 30px;
  font-weight: 100;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/*画面幅480px以上の設定
------------------------------------*/
@media screen and (max-width: 480px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
